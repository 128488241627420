.Nav{
    position: fixed;
    z-index: 999;
    width: 100%;
    height: auto;
    background-color: rgba(255, 255, 255, 0.39);
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: black;
    
    .Number{
       color:black;
       padding: 18px;
       cursor: pointer;
       font-weight: 700;
    }
    .Number:hover{
        color:rgba(243, 70, 70, 0.692);
        padding: 18px;
        cursor: pointer;
        font-weight: 700;
    }
}
.logo{
  margin-left: 3%;
}
.Active{
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 55px;
    margin: 0;
    background:rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0,0.3);
    .Number{
        padding: 18px;
        cursor: pointer;
     }
     .Number:hover{
         color:#faa09b ;
     }

}
.Header-Option{
    margin-top: 3%;
    position: inherit;
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #7aab00;
    div{ 
      display:flex;
      cursor: pointer;
        text-decoration: none;
        font-size:14px;
        letter-spacing: 2px;
        color: #7aab00;
        border-radius: 5px;
        font-family: 'Cinzel', serif;
        padding: 8px 20px;
    }
    div:hover{
        background-color: #7bab009f;
        color: #fff;
      }
}
.Down-Header-Option{
    margin-top: -3%;
    margin-left: 30%;
    position: inherit;
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    div{ 
      display:flex;
      cursor: pointer;
        text-decoration: none;
        font-size:14px;
        letter-spacing: 2px;
        color: #7aab00;
        border-radius: 5px;
        font-family: 'Cinzel', serif;
        padding: 8px 20px;
    }
    div:hover{
        background-color: #7bab009f;
        color: #fff;
      }
      
}
.Rightnavi{ 
    position: inherit;
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #7aab00;
    div{ 
      display:flex;
      cursor: pointer;
        text-decoration: none;
        font-size:14px;
        padding: 8px 25px;
        border-radius: 5px;
        letter-spacing: 2px;
        color: #7aab00;
        font-family: 'Cinzel', serif;
    }
    div:hover{
      background-color: #7bab009f;
      color: #fff;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0,0.3);
    }
}
.Rightnav{
    display: none;
}
.Rightnav2{
    display: none;
}
.Header-Container{
  display: flex;
  position: relative;
  width: 100vw;
  height: auto;
  align-items: center;
  justify-content: center;
  .Header-logo{
            width:100%;
            height: 50%;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0,0.3);
 }
 
 .Header-centerBox{
   display: flex;
   position: absolute;
  width: 50%;
  height: 50%;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.082);
  align-items: center;
  justify-content: center;
  text-align: center;
  .Header-button{
    position: absolute;
    width: 250px;
    height: 40px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    p{
      font-family: 'Philosopher', sans-serif;
      color: rgb(0, 0, 0);
      font-size: 1vw;
    }
  }
}

}

.Home-Header-Container{
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
  align-items: center;
  width: 100vw;
  right: 0%;
 .imageView{
   position: relative;
   width: 70vw;
  .Header-logo{
    width:100%;
    height:auto; 
}
 }

 }  

 .First-Box{
   top:20%;
    position:absolute;
    display: flex;
    align-items: center;
    width: 50vw;
  .Sub-story-paragraph{
    margin-left: 12vw;
    position: relative;
    justify-content: center;
    height: 100%;
    padding: 20px;
    border-radius: 25px;
    h1{
      font-size:50px;
      font-family: 'Roboto', sans-serif;
      color: #7aab00;
    }
    .sub-row{
      display: flex;
      margin-top: -70px;
      h1{
        font-size:50px;
        font-family: 'Roboto', sans-serif;
        color: #7aab00;
      }
      .fonth1{        font-size:50px;
        font-family: 'Roboto', sans-serif;
        margin-left: 10px;
        color: #4d5a0b;
      }
    }
    p{
      font-size:17px;
      line-height: 2.5vh;
      letter-spacing: 1px;
      font-family: 'Didact Gothic', sans-serif;
    }
   
  }
}

.commonButton{
  position: relative;
  border: none;
  font-size: 18px;
  border-radius: 15px;
  color: #FFFFFF;
  padding: 5px 10px;
  text-align: center;
   justify-content: center;
   align-items: center;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  overflow: hidden;
  background-image: linear-gradient(to right,#7aab00,#4d5a0b  );
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0,0.3);
  cursor: pointer;
  .commonButton-text{
    font-size:18px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
  }
}

.HomeButton{
  position: relative;
  border: none;
  font-size: 18px;
  border-radius: 15px;
  color: #FFFFFF;
  width: 200px;
  height: 45px;
  padding-bottom: 12px;
  text-align: center;
   justify-content: center;
   align-items: center;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  overflow: hidden;
  background-image: linear-gradient(to right,#7aab00,#4d5a0b  );
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0,0.3);
  cursor: pointer;
  .HomeButton-text{
    font-size:18px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
  }
}

.HomeButton:after {
  content: "";
  background:#4d5a0b;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px!important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
  overflow: hidden;
}

.HomeButton:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}

.commonButton:after {
  content: "";
  background:#4d5a0b;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px!important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
  overflow: hidden;
}

.commonButton:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}

.Sub-story-Container{
  margin-top: 5vw;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 25px;
  align-items: center;
  .Sub-story-Image{
    width:40vw;
    height: 30vw;
    border-radius: 12%;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0,0.3);
  }
  .Sub-story-paragraph{
    position: relative;
    width: 35vw;
    justify-content: center;
    height: 100%;
    h2{
      font-size:22px;
      font-family: 'Roboto', sans-serif;
      color: #4d5a0b;
    }
    p{
      font-size:17px;
      line-height: 2.5vh;
      letter-spacing: 1px;
      font-family: 'Didact Gothic', sans-serif;
    }
   
  }
}

.headingStyle{
  font-size:35px;
  font-family: 'Roboto', sans-serif;
  color: #7aab00;
  text-align: center;
}

.Mission-Container{
  margin-top: 5vw;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 25px;
  align-items: center;
  .Sub-story-paragraph{
    position: relative;
    width: 35vw;
    justify-content: center;
    height: 100%;
    text-align: center;
    // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0,0.3);
    padding: 20px;
    border-radius: 25px;
    h2{
      font-size:28px;
      font-family: 'Roboto', sans-serif;
      color: #4d5a0b;
    }
    p{
      font-size:15px;
      line-height: 2.5vh;
      letter-spacing: 1px;
      font-family: 'Didact Gothic', sans-serif;
    }
  }
  .verticalLine{
    height: 350px;
    width:2px;
    background-color:#7aab00;
  }
}

.Our-story-Container{
  position: relative;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 25px;
  align-items: flex-start;
  .Our-Sub-story-Image{
    margin-top: 30px;
    width:40vw;
    height: 30vw;
    border-radius: 12%;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0,0.3);
  }
  .Sub-Our-story-paragraph{
    position: relative;
    width: 35vw;
    justify-content: center;
    height: 100%;
    h2{
      font-size:22px;
      font-family: 'Roboto', sans-serif;
      color: #4d5a0b
    }
    p{
      font-size:17px;
      line-height: 2.5vh;
      letter-spacing: 1px;
      font-family: 'Didact Gothic', sans-serif;
    }
   
  }
}

.littleHeight{
 height: 18px
}

.story-carousel-Container{
  margin-top: 3%;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 5%;
  .story-carousel-image{
    width: auto;
    height: 50%;
  }
}

.product-web-row{
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  .product-logo-groot{
     margin-left: 275px;
     width: 120px;
     height: 80px;
  }
  .product-logo-krish{
    margin-right: 275px;
    width: 150px;
    height: 80px;
 }
}

.carousel-Container{
  margin-top: 3%;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 5%;
  // background-image: linear-gradient(to right,#7aab00,#4d5a0b  );
  .carousel-image{
    width: 72%;
    height: 50%;
  }
  /* square buttons */
.rec.rec-arrow {
  border-radius: 18%;
  background-image: linear-gradient(to right,#7aab00,#4d5a0b  );
  // box-shadow: inset 0 0 1px 1px lightgrey;
}
/* round buttons on hover */
.rec.rec-arrow:hover {
  border-radius: 50%;
  // box-shadow: inset 0 0 1px 1px lightgrey;
}
/* hide disabled buttons */
.rec.rec-arrow:disabled {
  visibility: hidden;
  // box-shadow: inset 0 0 1px 1px lightgrey;
}
/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
  outline: none;
  // box-shadow: inset 0 0 1px 1px lightgrey;
}
}

.commonHeight{
   height: 180px;
   width: 100vw;
   display: flex;
}

.commonHeightContact{
  height: 180px;
  width: 100vw;
  display: flex;
}

.commonLowHeight{
  height: 100px;
   width: 100vw;
   display: flex;

}

.Footer-Container{
  margin-top: 8%;
  display: flex;
  width: 100vw;
  justify-content: space-evenly;
  height: 200px;
 
   .Footer-box{
     width: 18%;
     p{
      font-size: 18px;
      color: #7aab00;
    }
   }
   .second-box-main{
     width: 25%;
     display: flex;
     flex-direction: column;
    .Second-box{
      width: '100%';
      display: flex;
      flex-direction: column;
      .second-text{
        h2{
          color: #4d5a0b;
        }
        p{
          font-size: 18px;
          color: #7aab00;
        }
      }
    }
    .second-box-social{
      width: 20%;
      display: flex;
      align-items: center;
    }
   }

}


.Contact-design-Container{
  background-color: rgb(255, 255, 255);
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: space-evenly;
  height: 200px;
  .Footer-box{
    width: 18%;
    h2{
      color: #4d5a0b;
    }
    p{
     font-size: 18px;
     color: #7e7e7e;
   }
  }
  .second-box-main{
    width: 18%;
    display: flex;
    flex-direction: column;
    
   .Second-box{
     width: '100%';
     display: flex;
     flex-direction: column;
     .second-text{
       h2{
         color: #4d5a0b;
       }
       p{
         font-size: 18px;
         color: #7e7e7e;
       }
     }
   }
}
}

.footer-copy-right-view{
 width: 100vw;
 display: flex;
 align-items: center;
 justify-content: center; 
 flex-direction: column;
 p{
  font-size: 15px;
  color: #7aab00;
 }
}
.Contact-copy-right-view{
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center; 
  flex-direction: column;
  background-color: #ffffff;
  p{
   font-size: 15px;
   color: #7aab00;
  }
}

.CommonTitle{
  font-size:28px;
  font-family: 'Roboto', sans-serif;
  color: #4d5a0b;
  text-align: center;
  letter-spacing: 2px;
}

.web-content-container{
  display: inline-block;
    width: max-content;
    height: max-content;
    background-color: #ffffff;
    padding: 10px;
    margin-top: 60px;
    text-align: center;
  .web-content{
    display: flex;
    width: 200px;
    height: 300px;
    flex-direction: column;
    // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
    .web-image-view{
      display: flex;
      width: '100%';
      height: 280px;
      background-color: #ffffff;
      align-items: center;
      justify-content: center;
      .web-image{
        width: 150px;
        height: auto;
        border-radius: 12px;
      }
    }
  }
  .web-content-text{
    width: 200px;
    padding-left: 15px;
    padding-right: 15px;
    P{
      font-size:17px;
      line-height: 2.5vh;
      letter-spacing: 1px;
      font-family: 'Didact Gothic', sans-serif;
      font-weight: bold;
    }
  }
}

.card-parent-main-view{
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  .card-main-view{
    align-items: center;
    width: 70vw;
    height: max-content;
    background-color: #ffffff;
    padding: 10px;
  
}
}
