@media(max-width:768px){
    *{
        margin: 0%;
        padding: 0%;
        overflow: visible;
    }
    .Nav,.Active{
        height: 55px;
        .Number{
            display: none;
        }
    }
    .Rightnavi{
        display: none;
    }
    .Header-Option{
        display: none;
    }
    .Down-Header-Option{
        display: none;
    }
    .logo{
        display: none;
    }
    .Rightnav{
        z-index: -1;
        display: flex;
        margin-left: 0%;
        flex-flow: column nowrap;
        background-color: rgb(19, 19, 19);
        height: 100vh;
        width: 280px;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0%;
        padding:0px 20px;
        padding-top: 3.5rem;
        list-style: none;
        text-align: left;
        li{
            display: flex;
            justify-content: center;
            margin-top: 10%;
            color: white;
            font-family: 'Cinzel', serif;
            padding: 5px;
            a{
                color: rgb(255, 255, 255);
                text-decoration: none;
                cursor: pointer;
              }
        }
        li:hover{
            background-color: #7aab00;
            }
        .nav-title{
            margin-top: 20%;
            border-top: 2px solid white;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
            
            h1{
                font-family: 'Philosopher', sans-serif;
                margin-top: 2%;
                color: white;
                font-size: 4vw;
                letter-spacing: 1px;
            }
            .nav-address{
                margin-top: 10%;
                display: flex;
                width: 100%;
                justify-content: space-between;
                .nav-right{
                    text-align: left;
                   span{
                       color: #ff6666;
                       font-family: cinzel;
                       font-size: 3.5vw;
                       font-weight: 700;
                   }
                   p{
                       color: white;
                       margin-top: 4%;
                       font-size: 2vw;
                       letter-spacing: 2px;
                       line-height:15px;
                   }
                }
                .nav-left{
                    text-align: center;
                    span{
                        color:#ff6666;
                        font-family: cinzel;
                        font-size: 3.5vw;
                        font-weight: 700;
                    }
                    p{
                        margin-top: 4%;
                        font-size: 2.5vw;
                        letter-spacing: 1px;
                        color: white;
                    }
                }
            }
        }
    }
    .Rightnav2{
        z-index: -1;
        display: flex;
        margin-left: 0%;
        flex-flow: column nowrap;
        background-color: rgb(19, 19, 19);
        width: 280px;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0%;
        padding:0px 20px;
        padding-top: 3.5rem;
        .user-profile{
            display:flex;
            width: 100%;
            height: 100px;
            justify-content: space-around;
            padding: 8px;
            img{
                border-radius: 50%;
                width: 100px;
                height: 100px;
            }
          .user-text{
              display: flex;
              justify-content: center;
              flex-direction: column;
              text-align: left;
              color: white;
              letter-spacing: 1px;
          }
        }
        .user-options{
            div{
                display: flex;
                margin-top: 10%;
                color: white;
                font-family: 'Cinzel', serif;
                padding: 5px;
                text-decoration: none;
               cursor: pointer;
            }
            div:hover{
                background-color: #7aab00;
                }
        }
    }
    .Home-Header-Container{
        position: relative;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 25px;
        align-items: center;
        width: 100vw;
        right: 0%;
       .imageView{
         position: relative;
         width: 70vw;
        .Header-logo{
          width:100%;
          height:auto; 
      }
       }
      
       } 
    .First-Box{
        top:5%;
        position:absolute;
        display: flex;
        align-items: center;
        width: 50vw;
       .Sub-story-paragraph{
         margin-left: .5vw;
         position: relative;
         justify-content: center;
         height: 100%;
         padding: 20px;
         border-radius: 25px;
         h1{
           font-size:18px;
           font-family: 'Roboto', sans-serif;
           color: #7aab00;
         }
         .sub-row{
           display: flex;
           margin-top: -1px;
           margin-bottom: 5px;
           h1{
             font-size:18px;
             font-family: 'Roboto', sans-serif;
             color: #7aab00;
           }
           .fonth1{
             font-size:18px;
             font-family: 'Roboto', sans-serif;
             margin-left: 10px;
             color: #4d5a0b;
           }
         }
         p{
           display: none;
           font-size:10px;
           line-height: 1.3vh;
           letter-spacing: 1px;
           font-family: 'Didact Gothic', sans-serif;
           margin-bottom: 10px;
         }
        
       }
       .HomeButton{
         p{
           display: flex;
           text-align: center;
           margin-left: 10px;
         }
       }
     }
     .Sub-story-Container{
        margin-top: 5vw;
        position: relative;
        display: flex;
        margin-bottom: 25px;
        align-items: center;
        flex-direction: column;
        .Sub-story-Image{
          margin-top: 12px;
          width:60vw;
          height: 40vw;
          border-radius: 12%;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0,0.3);
        }
        .Sub-story-paragraph{
            margin-top: 12px;
          position: relative;
          width: 80vw;
          justify-content: center;
          height: 100%;
          h2{
             
            font-size:12px;
            font-family: 'Roboto', sans-serif;
            color: #4d5a0b;
          }
          p{
            margin-top: 8px;
            font-size:12px;
            line-height: 2vh;
            letter-spacing: 1px;
            font-family: 'Didact Gothic', sans-serif;
            margin-bottom: 8px;
          }
         
        }
      }
      .CommonTitle{
        font-size:15px;
        font-family: 'Roboto', sans-serif;
        color: #4d5a0b;
        text-align: center;
        letter-spacing: 2px;
      }
      .carousel-Container{
        margin-top: 0%;
        font-family: sans-serif;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 5%;
        .carousel-image{
          width: auto;
          height: 40%;
        }
      }
      .Mission-Container{
        margin-top: 5vw;
        position: relative;
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 25px;
        align-items: center;
       flex-direction: column;
        .Sub-story-paragraph{
          position: relative;
          width: 80vw;
          justify-content: center;
          height: 100%;
          text-align: center;
          // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0,0.3);
          padding: 20px;
          border-radius: 25px;
          h2{
            font-size:12px;
            font-family: 'Roboto', sans-serif;
            color: #4d5a0b;
            margin-bottom: 10px;
          }
          p{
            font-size:12px;
            line-height: 1.8vh;
            letter-spacing: 1px;
            font-family: 'Didact Gothic', sans-serif;
            font-weight: bold;
          }
        }
        .verticalLine{
          height: 2px;
          width:250px;
          background-color:#7aab00;
        }
      }
      .Footer-Container{
        margin-top: 20%;
        display: flex;
        width: 100vw;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 200px;
         .Footer-box{
           width: 80vw;
           p{
               margin-top: 8px;
            font-size: 12px;
            color: #7aab00;
          }
         }
         .second-box-main{
           width: 80vw;
           display: flex;
           flex-direction: column;
          .Second-box{
            width: '100%';
            display: flex;
            flex-direction: column;
            .second-text{
              h2{
                font-size: 16px;
                color: #4d5a0b;
                margin-top: 8px;
              }
              p{
                margin-top: 8px;
                font-size: 12px;
                color: #7aab00;
              }
            }
          }
          .second-box-social{
            margin-top: 10px;
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
          }
         }
      
      }
      .footer-copy-right-view{
          margin-top: 30px;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center; 
        flex-direction: column;
        margin-bottom: 30px;
        .second-box-social{
           margin-bottom: 30px; 
        }
        p{
         font-size: 12px;
         color: #4d5a0b;
        }
       }
       .Our-story-Container{
        position: relative;
        display: flex;
        margin-bottom: 25px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .Our-Sub-story-Image{
          width:80vw;
          height: 50vw;
          border-radius: 12%;
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0,0.3);
        }
        .Sub-Our-story-paragraph{
          position: relative;
          width: 80vw;
          justify-content: center;
          height: 100%;
          margin-bottom: 18px;
          h2{
            margin-top: 18px;
            font-size:18px;
            font-family: 'Roboto', sans-serif;
            color: #4d5a0b
          }
          p{
              margin-top: 12px;
            font-size:12px;
            line-height: 2vh;
            letter-spacing: 1px;
            font-family: 'Didact Gothic', sans-serif;
          }
         
        }
      }
      .commonHeight{
        height: 40px;
        width: 100vw;
        display: flex;
     }
     .commonHeightContac{
        height: 180px;
        width: 100vw;
        display: flex;
     }
     .Contact-design-Container{
        background-color: rgb(255, 255, 255);
        display: flex;
        width: 90vw;
        justify-content: space-between;
        height: 200px;
        margin-left: 5vw;
        .Footer-box{
          width: 45%;
          h2{
            font-size: 18px;
            color: #4d5a0b;
          }
          p{
            margin-top: 12px;
           font-size: 12px;
           color: #7e7e7e;
         }
        }
        .second-box-main{
          width: 30%;
          display: flex;
          flex-direction: column;
         .Second-box{
           width: '100%';
           display: flex;
           flex-direction: column;
           .second-text{
             h2{
                font-size: 18px;
               color: #4d5a0b;
             }
             p{
                 margin-top: 12px;
               font-size: 12px;
               color: #7e7e7e;
             }
           }
         }
      }
   
      }
      .HomeButton{
        margin-top: 18px;
        border: none;
        font-size: 18px;
        border-radius: 15px;
        color: #FFFFFF;
        width: 100px;
        height: 15px;
         justify-content: center;
         align-items: center;
        -webkit-transition-duration: 0.4s; /* Safari */
        transition-duration: 0.4s;
        overflow: hidden;
        background-image: linear-gradient(to right,#7aab00,#4d5a0b  );
        box-shadow: 0px 5px 16px 0px rgba(0, 0, 0,0.3);
        cursor: pointer;
        .HomeButton-text{
          margin-top: 10px;
          text-align: center;
          font-size:18px;
          font-weight: bold;
          font-family: 'Roboto', sans-serif;
        }
      }
      .Contact-copy-right-view{
        margin-top: -80px;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center; 
        flex-direction: column;
        .second-box-social{
          margin-bottom: 20px; 
       }
       p{
        font-size: 12px;
        color: #4d5a0b;
       }
      }
      .product-logo-groot{
        display: none;
      }
      .product-logo-krish{
        display: none;
      }
      .card-main-view{
        columns: 2;
      }
      .web-content-container{
        display: inline-block;
        width: 150px;
        height: 190px;
        background-color: #ECECEC;
        padding: 1px;
        margin-top: 60px;
        margin-left: 5px;
        text-align: center;
        align-items: center;
        justify-content: center;
        border: 2px solid #ECECEC;
        border-top-left-radius: 6%;
        border-top-right-radius: 6%;
        border-bottom-left-radius: 8%;
        border-bottom-right-radius: 8%;
        .web-content{
          display: flex;
          padding-top: 3%;
          width: 150px;
          height: 180px;
          flex-direction: column;
          align-items: center;
          // box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.192);
          .web-image-view{
            display: flex;
            width: 140px;
            height: 160px;
            background-color: #ffffff;
            align-items: center;
            justify-content: center;
            .web-image{
              width: 60px;
              height: auto;
              border-radius: 12px;
            }
          }
        }
        .web-content-text{
          padding-top: 12px;
          width: 150px;
          padding-left: 0px;
          padding-right: 15px;
          P{
            font-size:12px;
            line-height: 2.5vh;
            letter-spacing: 1px;
            font-family: 'Didact Gothic', sans-serif;
            font-weight: bold;
            color: #7aab00;
          }
        }
      }
      .card-parent-main-view{
        display: flex;
        width: 100vw;
        align-items: center;
        justify-content: flex-start;
        background-color: rgb(255, 255, 255);
        .card-main-view{
          align-items: center;
          width: 100vw;
          height: max-content;
          background-color: #ffffff;
          padding: 10px;
        
      }
    }
}